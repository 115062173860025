import axios from 'axios'
import NProgress from 'nprogress'
import { notification } from 'antd'
import { store } from 'index'
import {
  API_URL,
  API_URL_WORKORDER,
  API_URL_REPORT,
  API_URL_INVENTORY,
  NETWORK_ERROR,
  NETWORK_ERROR_MESSAGE,
  API_URL_PRINT,
} from '../constants/index'

const token = localStorage.getItem('token')

const config = {
  headers: { Authorization: `Bearer ${token}` },
}

const netWorkErrorNotification = () => {
  notification.error({
    message: NETWORK_ERROR,
    duration: 0,
    description: NETWORK_ERROR_MESSAGE,
  })
}

const errorNotificationUnknown2 = message => {
  notification.error({
    message: '',
    description: message,
    duration: 0,
  })
}

export const getWorkorderID = () => {
  const state = store.getState()
  return state &&
    state.workOrders &&
    state.workOrders.editing &&
    state.workOrders.editing.workOrderID
    ? state.workOrders.editing.workOrderID
    : '0'
}

export const getInventoryID = () => {
  const state = store.getState()
  return state &&
    state.inventorys &&
    state.inventorys.editing &&
    state.inventorys.editing.inventoryID
    ? state.inventorys.editing.inventoryID
    : '0'
}

export const getInventoryNumber = () => {
  const state = store.getState()
  return state &&
    state.inventorys &&
    state.inventorys.editing &&
    state.inventorys.editing.inventoryNumber
    ? state.inventorys.editing.inventoryNumber
    : '0'
}

export const getContractAppType = () => {
  const state = store.getState()
  return state && state.contracts && state.contracts.selected && state.contracts.selected.payload
    ? state.contracts.selected.payload.appType
    : ''
}

export const getcompanyIdNumber = () => {
  const state = store.getState()
  return state && state.companys && state.companys.selected && state.companys.selected.payload
    ? state.companys.selected.payload.companyID
    : ''
}

export const getcontractIdNumber = () => {
  const state = store.getState()
  return state && state.contracts && state.contracts.selected && state.contracts.selected.payload
    ? state.contracts.selected.payload.contractID
    : localStorage.getItem('contractID')
}

export const getInvoice2ID = () => {
  const state = store.getState()
  return state && state.invoice2s && state.invoice2s.editing && state.invoice2s.editing.invoiceID
    ? state.invoice2s.editing.invoiceID
    : ''
}

export const getAreaID = () => {
  const state = store.getState()
  return state && state.iventorys && state.iventorys.editing && state.iventorys.editing.areaID
    ? state.iventorys.editing.areaID
    : ''
}

export const getPartOrderID = () => {
  const state = store.getState()
  return state &&
    state.partOrders &&
    state.partOrders.editing &&
    state.partOrders.editing.partOrderID
    ? state.partOrders.editing.partOrderID
    : '0'
}

export const getPartTransferID = () => {
  const state = store.getState()
  return state &&
    state.partTransfers &&
    state.partTransfers.editing &&
    state.partTransfers.editing.partTransferID
    ? state.partTransfers.editing.partTransferID
    : '0'
}

export const getProposalID = () => {
  const state = store.getState()
  return state && state.proposals && state.proposals.editing && state.proposals.editing.proposalID
    ? state.proposals.editing.proposalID
    : '0'
}

export const getInvoiceID = () => {
  const state = store.getState()
  return state && state.invoices && state.invoices.editing && state.invoices.editing.invoiceID
    ? state.invoices.editing.invoiceID
    : '0'
}

export const getProposalWorkOrderID = () => {
  const state = store.getState()
  return state && state.proposals && state.proposals.editing && state.proposals.editing.workOrderID
    ? state.proposals.editing.workOrderID
    : '0'
}

export const getContractPartnerID = () => {
  const state = store.getState()
  return state &&
    state.contractPartners &&
    state.contractPartners.editing &&
    state.contractPartners.editing.contractPartnerID
    ? state.contractPartners.editing.contractPartnerID
    : '0'
}

export const getContractInvoicePartnerID = () => {
  const state = store.getState()
  return state &&
    state.contractInvoicePartners &&
    state.contractInvoicePartners.editing &&
    state.contractInvoicePartners.editing.contractInvoicePartnerID
    ? state.contractInvoicePartners.editing.contractInvoicePartnerID
    : '0'
}

export const getContractServicePartnerID = () => {
  const state = store.getState()
  return state &&
    state.contractServicePartners &&
    state.contractServicePartners.editing &&
    state.contractServicePartners.editing.contractServicePartnerID
    ? state.contractServicePartners.editing.contractServicePartnerID
    : '0'
}

const errorNotificationUnknown = message => {
  notification.error({
    message: NETWORK_ERROR,
    description: message,
    duration: 0,
  })
}

export const getMicroBaseApiUrl = url => {
  // eslint-disable-next-line no-underscore-dangle
  let _url = API_URL
  if (url.startsWith('dropdown')) {
    _url = API_URL
  } else if (url.startsWith('workOrder')) {
    console.log('getMicroBaseApiUrl workOrder')
    _url = API_URL_WORKORDER
  } else if (
    url.startsWith('costType') ||
    url.startsWith('inventory') ||
    url.startsWith('device')
  ) {
    _url = API_URL_INVENTORY
  } else if (url.startsWith('report')) {
    _url = API_URL_REPORT
  } else if (url.startsWith('token')) {
    _url = API_URL
  }
  return _url
}

export async function downloadRequest(url) {
  window.open(`${getMicroBaseApiUrl(url)}${url}`)
}

export async function getRequestReportViewer(url) {
  try {
    if (url.includes('http')) {
      const response = await axios.get(url, config)
      return response.data
    }

    const response = await axios.get(`${API_URL_PRINT}${url}`, config)
    return response.data
  } catch (error) {
    if (error.response) {
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    }
    return null
  }
}

export async function getRequest(url) {
  try {
    if (url.includes('http')) {
      const response = await axios.get(url, config)
      return response.data
    }

    const response = await axios.get(`${getMicroBaseApiUrl(url)}${url}`, config)
    // Success 🎉
    return response.data
  } catch (error) {
    console.error(error)
    // Error 😨
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)

      if (error.response.status === 401) {
        // unauthorized
        localStorage.removeItem('token')
      } else if (error.response.status === 500) {
        // server error
        errorNotificationUnknown(error.response.data.title)
      } else if (error.response.status === 400) {
        // bad request
        if (typeof error.response.data === 'string' || error.response.data instanceof String) {
          errorNotificationUnknown2(error.response.data)
        } else {
          errorNotificationUnknown2(error.message)
        }
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request)
      errorNotificationUnknown(error.message)
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log('Error', error.message)
    }

    if (error.message === 'Network Error') {
      netWorkErrorNotification()
    }

    return null
  }
}

export async function uploadWorkOrderFile(url, workOrderID, contractID, file) {
  const data = new FormData()
  data.append('ContractID', contractID)
  data.append('OrderNumber', workOrderID)
  data.append('File', file)
  const config2 = config
  // config2.headers['Content-Type'] = 'multipart/form-data'
  try {
    if (url.includes('http')) {
      const response = await axios.post(url, data, config2)
      return response.data
    }

    const response = await axios.post(url, data, config2)
    return response.data
  } catch (error) {
    // Error 😨
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      if (typeof error.response.data === 'string' || error.response.data instanceof String) {
        if (error.response.status === 400) {
          errorNotificationUnknown2(error.response.data)
        } else {
          errorNotificationUnknown(error.response.data)
        }
      } else {
        errorNotificationUnknown(error.message)
      }
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
      if (error.response.status === 401) {
        localStorage.removeItem('token')
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request)
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log('Error', error.message)
    }
    if (error.message === 'Network Error') {
      if (error.response.status !== 401) {
        netWorkErrorNotification()
      }
    }
    return null
  }
}

export async function uploadPartOrderDocument(url, partOrderID, file) {
  const data = new FormData()
  data.append('OrderNumber', partOrderID)
  data.append('File', file)
  const config2 = config
  // config2.headers['Content-Type'] = 'multipart/form-data'
  try {
    if (url.includes('http')) {
      const response = await axios.post(url, config, config2)
      return response.data
    }

    const response = await axios.post(url, data, config2)
    return response.data
  } catch (error) {
    // Error 😨
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      if (typeof error.response.data === 'string' || error.response.data instanceof String) {
        if (error.response.status === 400) {
          errorNotificationUnknown2(error.response.data)
        } else {
          errorNotificationUnknown(error.response.data)
        }
      } else {
        errorNotificationUnknown(error.message)
      }
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
      if (error.response.status === 401) {
        localStorage.removeItem('token')
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request)
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log('Error', error.message)
    }
    if (error.message === 'Network Error') {
      if (error.response.status !== 401) {
        netWorkErrorNotification()
      }
    }
    return null
  }
}

export async function uploadPartFile(url, number, file) {
  const data = new FormData()
  data.append('OrderNumber', number)
  data.append('File', file)
  const config2 = config
  // config2.headers['Content-Type'] = 'multipart/form-data'
  try {
    if (url.includes('http')) {
      const response = await axios.post(url, config, config2)
      return response.data
    }

    const response = await axios.post(url, data, config2)
    return response.data
  } catch (error) {
    // Error 😨
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      if (typeof error.response.data === 'string' || error.response.data instanceof String) {
        if (error.response.status === 400) {
          errorNotificationUnknown2(error.response.data)
        } else {
          errorNotificationUnknown(error.response.data)
        }
      } else {
        errorNotificationUnknown(error.message)
      }
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
      if (error.response.status === 401) {
        localStorage.removeItem('token')
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request)
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log('Error', error.message)
    }
    if (error.message === 'Network Error') {
      if (error.response.status !== 401) {
        netWorkErrorNotification()
      }
    }
    return null
  }
}

export async function uploadProposalFile(url, file) {
  const data = new FormData()
  data.append('OrderNumber', '')
  data.append('File', file)

  const config2 = config
  // config2.headers['Content-Type'] = 'multipart/form-data'
  try {
    if (url.includes('http')) {
      const response = await axios.post(url, config, config2)
      return response.data
    }

    const response = await axios.post(url, data, config2)
    return response.data
  } catch (error) {
    // Error 😨
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      if (typeof error.response.data === 'string' || error.response.data instanceof String) {
        if (error.response.status === 400) {
          errorNotificationUnknown2(error.response.data)
        } else {
          errorNotificationUnknown(error.response.data)
        }
      } else {
        errorNotificationUnknown(error.message)
      }
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
      if (error.response.status === 401) {
        localStorage.removeItem('token')
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request)
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log('Error', error.message)
    }
    if (error.message === 'Network Error') {
      if (error.response.status !== 401) {
        netWorkErrorNotification()
      }
    }
    return null
  }
}

export async function postRequest(url, param, customHeader) {
  console.log('postRequest', url, param, customHeader)
  try {
    if (url.includes('http')) {
      if (customHeader) {
        config.headers = {
          ...config.headers,
          ...customHeader,
        }
      }
      const response = await axios.post(url, param.values, config)
      return response.data
    }

    const response = await axios.post(`${getMicroBaseApiUrl(url)}${url}`, param.values, config)
    // Success 🎉
    return response.data
  } catch (error) {
    console.error(error)
    console.log(error.response)
    console.log(error.request)
    // Error 😨
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      if (typeof error.response.data === 'string' || error.response.data instanceof String) {
        if (error.response.status === 400) {
          errorNotificationUnknown2(error.response.data)
        } else {
          errorNotificationUnknown(error.response.data)
        }
      } else {
        errorNotificationUnknown(error.message)
      }
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
      if (error.response.status === 401) {
        localStorage.removeItem('token')
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request)
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log('Error', error.message)
    }

    return null
  }
}

export async function deleteRequest(url) {
  // console.log(url, param)
  try {
    if (url.includes('http')) {
      const response = await axios.delete(url, config)
      return response.data
    }

    const response = await axios.delete(`${getMicroBaseApiUrl(url)}${url}`, config)

    return response.data
  } catch (error) {
    console.error(error)
    return null
  }
}

axios.interceptors.request.use(
  c => {
    // Do something before request is sent
    // console.log('Start Ajax Call');
    if (NProgress.isStarted() === false) {
      NProgress.start()
    }
    return c
  },
  error => {
    // Do something with request error
    NProgress.done()
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  response => {
    // Do something with response data
    // console.log('Done with Ajax call');
    if (NProgress.isStarted() === true) {
      NProgress.done()
    }
    return response
  },
  error => {
    // Do something with response error
    NProgress.done()
    return Promise.reject(error)
  },
)
