import { notification } from 'antd'
import {
  getRequest,
  getcontractIdNumber,
  getRequestReportViewer,
  downloadRequest,
  getWorkorderID,
} from './api'

const token = localStorage.getItem('token')

const returnExcelOrGET = (url, excel) => {
  if (excel) {
    downloadRequest(url)
  } else {
    return getRequest(url)
  }
  return null
}

export async function getWorkOrders(
  size,
  index,
  searchTerm,
  orderBy,
  workOrderStatus,
  workOrderSubStatus,
  area,
  location,
  address,
  begin,
  end,
  contactName,
  contactPhone,
  workOrderType,
  inventoryNumber,
  excel,
) {
  if (workOrderStatus === undefined || workOrderStatus === null) {
    workOrderStatus = '%20'
  } else if (
    workOrderStatus.length > 0 &&
    workOrderStatus.length <= 1 &&
    workOrderStatus[0] === null
  ) {
    workOrderStatus = '%20'
  }
  const contractID = getcontractIdNumber()
  const url = `report/workorder-report/${size || '15'}/${index || '0'}/${searchTerm ||
    '%20'}/${orderBy || '%20'}/${contractID}/${workOrderStatus || '%20'}/${workOrderSubStatus ||
    '%20'}/${area || '%20'}/${location || '%20'}/${address || '%20'}/${begin || '%20'}/${end ||
    '%20'}/${contactName || '%20'}/${contactPhone || '%20'}/${workOrderType ||
    '%20'}/${inventoryNumber || '%20'}/${excel || 'false'}/${token}`
  return returnExcelOrGET(url, excel)
}

export async function getWorkOrders23(
  workOrderStatus,
  workOrderSubStatus,
  area,
  location,
  address,
  begin,
  end,
  contactName,
  contactPhone,
  workOrderType,
  inventoryNumber,
) {
  if (workOrderStatus === undefined || workOrderStatus === null) {
    workOrderStatus = '%20'
  } else if (
    workOrderStatus.length > 0 &&
    workOrderStatus.length <= 1 &&
    workOrderStatus[0] === null
  ) {
    workOrderStatus = '%20'
  }
  const contractID = getcontractIdNumber()
  const url = `report/workorder-report3/${contractID}/${workOrderStatus ||
    '%20'}/${workOrderSubStatus || '%20'}/${area || '%20'}/${location || '%20'}/${address ||
    '%20'}/${begin || '%20'}/${end || '%20'}/${contactName || '%20'}/${contactPhone ||
    '%20'}/${workOrderType || '%20'}/${inventoryNumber || '%20'}/${token}`
  return returnExcelOrGET(url, true)
}

export async function getInventories(
  size,
  index,
  searchTerm,
  orderBy,
  intervalID,
  area,
  location,
  address,
  account,
  emHBarcode,
  begin,
  end,
  excel,
) {
  const contractID = getcontractIdNumber()
  const url = `report/inventory-report/${size || '15'}/${index || '0'}/${searchTerm ||
    '%20'}/${orderBy || '%20'}/${contractID}/${intervalID || '%20'}/${area || '%20'}/${location ||
    '%20'}/${address || '%20'}/${account || '%20'}/${emHBarcode || '%20'}/${begin || '%20'}/${end ||
    '%20'}/${excel || 'false'}/${token}`
  return returnExcelOrGET(url, excel)
}

export async function getClassCodeCosts(
  workOrderStatus,
  location,
  area,
  address,
  begin,
  end,
  excel,
) {
  const contractID = getcontractIdNumber()
  const url = `report/classcode-report/${contractID}/${workOrderStatus || '%20'}/${location ||
    '%20'}/${area || '%20'}/${address || '%20'}
    /${begin || '%20'}/${end || '%20'}/${excel || 'false'}/${token}`
  return returnExcelOrGET(url, excel)
}

export async function getMaintenanceReportPreview(status, startDate, endDate, classID) {
  const contractID = getcontractIdNumber()
  return getRequest(
    `report/maintenance-report-preview/${contractID}/${status || '9999'}/${startDate ||
      '0'}/${endDate || '0'}/${classID || '0'}/${token}`,
  )
}

export async function getWOClassCodeCosts(
  workOrderStatus,
  location,
  area,
  address,
  begin,
  end,
  excel,
) {
  const contractID = getcontractIdNumber()
  const url = `report/wo-classcode-report/${contractID}/${workOrderStatus || '%20'}/${location ||
    '%20'}/${area || '%20'}/${address || '%20'}
    /${begin || '%20'}/${end || '%20'}/${excel || 'false'}/${token}`
  return returnExcelOrGET(url, excel)
}

export async function getAssetTecReport(inventoryid) {
  const contractid = getcontractIdNumber()
  return getRequest(`report/get-asset-tec-report/${contractid}/${inventoryid}/${token}`)
}

export async function getAssetTecCount(inventoryid, workOrderID) {
  const contractid = getcontractIdNumber()
  return getRequest(
    `report/get-asset-tec-report-count/${contractid}/${inventoryid}/${workOrderID}/${token}`,
  )
}

export async function getPreventiveMaintenanceReport(startDate, endDate) {
  const contractid = getcontractIdNumber()
  return getRequest(
    `report/preventiveMaintenanceReport?contractID=${contractid}&startDate=${startDate}&endDate=${endDate}`,
  )
}

export async function getBillingTabulation(startDate, endDate) {
  const contractid = getcontractIdNumber()
  return getRequest(
    `report/billingTabulation?contractID=${contractid}&startDate=${startDate}&endDate=${endDate}`,
  )
}

export async function sendEmail() {
  return getRequestReportViewer(`report/workordersendtomail?id=${getWorkorderID()}&token=${token}`)
}

export async function windowOpen(url, dontAddToken) {
  try {
    if (dontAddToken === undefined) {
      const win = window.open(`${url}&token=${token}`, '_blank')
      win.focus()
    } else {
      const win = window.open(`${url}`, '_blank')
      win.focus()
    }
  } catch (e) {
    notification.error({
      message: 'Window Fehler',
      description: e.message,
    })
  }
}
