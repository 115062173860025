import actions from './actions'

export default function ContractsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_CONTRACT:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.SELECT_CONTRACT:
      console.log('SELECT_CONTRACT', action.payload)
      return {
        ...state,
        selected: action.payload,
        loading: false,
      }
    case actions.UNSELECT_CONTRACT_RECIEVED:
      return {
        ...state,
        selected: null,
        loading: false,
      }
    case actions.EDIT_CONTRACT:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_CONTRACT:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
      }
    case actions.NEW_CONTRACT:
      return {
        ...state,
        editing: { contractID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_CONTRACT_RECIEVED ||
      actions.UPDATE_CONTRACT_RECIEVED ||
      actions.DELETE_CONTRACT_RECIEVED:
      return {
        ...state,
        editing: { contractID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    default:
      return state
  }
}
